<template>
    <div>
        <div v-if="section_id != null && !showExam" class="row">
            <div class="col-md-8">
                <span style="font-size: 20px;color: black">{{ $t('exam_definition') }}</span><span> | </span>
                <span style="font-size: 16px">{{ courseName }}</span>
            </div>
            <div class="col-md-4 new-button">
                <b-button variant="success" @click="addExam"><i class="ri-add-line mr-1 "></i>{{ $t('new') }}</b-button>
            </div>
        </div>
        <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows" :title="null"
            :show-paginate="false" :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
            v-show="datatable.showTable">
        </datatable>
        <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                {{ $t('edit') }}
            </template>
            <template v-slot:CommonModalContent>
                <UpdateForm v-if="formProgress == 'update'" :formId="formId" :sectionId="section_id"
                    @successUpdate="successUpdate" />
            </template>
        </CommonModal>
        <CommonModal ref="createForm" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                {{ $t('create') }}
            </template>
            <template v-slot:CommonModalContent>
                <CreateForm v-if="formProgress == 'create'" :formId="formId" :sectionId="section_id"
                    @successCreate="successCreate" />
            </template>
        </CommonModal>
    </div>
</template>
<script >
import ExamDefinationService from "@/services/ExamDefinationService";
import qs from "qs";
import UpdateForm from "@/modules/prepExamDefinitions/pages/ExamDefinitionTable/UpdateForm.vue"
import CreateForm from "@/modules/prepExamDefinitions/pages/ExamDefinitionTable/CreateForm.vue";

export default {
    name: "ExamDefinitionTable",
    components: {
        CreateForm, UpdateForm
    },
    props: {
        section_id: {
            type: Number
        },
        courseName: {
            type: String
        }
    },
    data() {
        return {
            showExam: false,
            examId: null,
            numberOfExams: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('exams'),
                                class: 'ri-article-line',
                                permission: 'city_delete',
                                callback: (row) => {
                                    this.examsModal(row, row.exam_type_text);
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'city_update',
                                callback: (row) => {
                                    this.updateModal(row.id);
                                }
                            },


                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: 'city_delete',
                                callback: (row) => {
                                    this.formDelete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('exam_type'),
                        field: 'exam_type_text',
                        sortable: false,
                    },
                    {
                        label: this.$t('number_of_exams'),
                        field: 'number_of_exams',
                        sortable: false,
                    },
                    {
                        label: this.$t('effect_ratio'),
                        field: 'effect_ratio',
                        sortable: false,
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: true,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProgress: null,
            formId: null,
            examType: null
        }
    },
    watch: {
        section_id(val) {
            this.getRows(val)
        }
    },
    methods: {
        formClear() {
            this.formProgress = null
            this.formId = null
        },
        getRows(value) {
            const config = {
                params: {
                    filter: {
                        section_id: value
                    },
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };
            ExamDefinationService.getAll(config)
                .then(response => {
                    this.datatable.rows = response.data.data
                }).catch(err => {
                    this.showErrors(err)
                })
        },
        updateModal(id) {
            this.formId = id
            this.formProgress = 'update'
            this.$refs.showModal.$refs.commonModal.show();
        },
        successUpdate() {
            this.formClear()
            this.$refs.showModal.$refs.commonModal.hide();
            this.getRows(this.section_id)
        },
        successCreate() {
            this.formClear()
            this.$refs.createForm.$refs.commonModal.hide();
            this.getRows(this.section_id)
        },
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        ExamDefinationService.deletes(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows(this.section_id);
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
        examsModal(row, examType) {
            this.examId = row.id
            this.examType = examType
            this.numberOfExams = row.number_of_exams
            this.$emit('setExamType', { examType, examId: row.id, number_of_exams: row.number_of_exams })
            this.$emit('tableStatusUpdate', 'exam')
        },
        addExam() {
            this.formProgress = 'create'
            this.$refs.createForm.$refs.commonModal.show();
        }

    },
    mounted() {
        if (this.section_id) {
            this.getRows(this.section_id)
        }

    },
}
</script>

<style>
.new-button {
    display: flex;
    justify-content: flex-end;
}
</style>
