<template>
    <div>
        <div>
            <ValidationObserver ref="form">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <ValidationProvider name="exam_type" rules="required" v-slot="{valid, errors}">
                            <b-form-group :label="$t('exam_type')">
                                <ParameterSelectbox
                                code="exam_schedules_types"
                                :validate-error="errors[0]"
                                v-model="form.exam_type"
                                ></ParameterSelectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <ValidationProvider name="number_of_exams" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('number_of_exams')">
                                <b-form-input type="number" :min="1"
                                              :max="20"
                                v-model="form.number_of_exams">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                        <ValidationProvider name="effect_ratio" rules="required" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('effect_ratio')">
                                <b-form-input type="number" :min="0"
                                              :max="100"
                                 v-model="form.effect_ratio">
                                </b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-12 mt-3 d-flex">
                    <b-button
                        @click="updateForm"
                        type="button"
                        variant="primary"
                        class="btn-lg mr-2"
                    >
                        {{ $t("save") | toUpperCase }}
                    </b-button>
                </div>
            </ValidationObserver>

        </div>
    </div>
</template>
<script>
import examDefinationService from "@/services/ExamDefinationService";

export default {
    props:{
        formId:{
            type:Number
        },
        sectionId:{
            type:Number
        }
    },
    data(){
        return{
            form:{
                exam_type:null,
                effect_ratio:null,
                number_of_exams:null
            }
        }
    },
    watch:{
      formId(newValue){
          this.getRows(newValue)
      }
    },
    created() {
        this.getRows(this.formId)
    },
    methods:{
       async updateForm(){
           let isValid = await this.$refs.form.validate()
           if(isValid){
               let formData={
                   exam_type:this.form.exam_type,
                   effect_ratio:this.form.effect_ratio,
                   number_of_exams:this.form.number_of_exams,
                   section_id:this.sectionId
               }
               examDefinationService.put(this.formId,formData)
                   .then(response =>{
                       this.$toast.success(response.data.message)
                       this.$emit('successUpdate',true)
                   }).catch(err=>{
                       this.showErrors(err)
               })
           }

        },
        getRows(id){
           examDefinationService.get(id)
               .then(response=>{
                   let data =response.data.data
                   this.form.exam_type=data.exam_type
                   this.form.effect_ratio=data.effect_ratio
                   this.form.number_of_exams=data.number_of_exams
               }).catch(err=>{
                   this.showErrors(err)
           })
        }
    }
}
</script>
